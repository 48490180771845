import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CommentIcon from '@mui/icons-material/Comment';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FactoryIcon from '@mui/icons-material/Factory';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';
import { Avatar, Collapse, MenuItem, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

import * as React from 'react';
import Div100vh from 'react-div-100vh';
import { useDispatch } from 'react-redux';

import logo from 'assets/images/appLogo.png';
import { Breadcrumbs } from 'components/Elements/Breadcrumbs';
import { Footer } from 'components/Elements/Footer/Footer';

import MenuPopover from 'components/Elements/MenuPopover/MenuPopover';
import { NavItem } from 'components/Elements/Navigate/NavItem';
import { useAuth } from 'lib/auth';
import {
  DASHBOARD_VIEW,
  USER_LIST_VIEW,
  USER_CREATE_VIEW,
  INQUIRIES_LIST_VIEW,
  PRODUCT_LIST_VIEW,
  PAYMENTS_LIST_VIEW,
  REQUEST_LIST_VIEW,
  ORDER_LIST_VIEW,
  EXHIBIT_LIST_VIEW,
  ADMINISTRATOR_LIST_VIEW,
  COMMENT_LIST_VIEW,
  ATRS_IMPORT_ERROR_LIST_VIEW,
  WITHDRAWAL_LIST_VIEW,
} from 'routes/urls';

import { fetchMyAccount, logoutAdministrator } from 'stores/administratorSlice';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    type: 'light',
    partsOne: {
      main: '#3C4B64',
      background: '#F3F3F3',
    },
    safetyGreen: {
      main: '#3C4B64',
      background: '#3ad798',
    },
    errorRed: {
      main: '#3C4B64',
      background: '#f84f56',
    },
    warningYellow: {
      main: '#3C4B64',
      background: '#ffb453',
    },
    disableGray: {
      main: '#3C4B64',
      background: '#c4c4c4',
    },
    white: {
      main: '#FFFFFF',
    },
  },
});

export const MainLayout = ({ children, links }) => {
  const { logout, user } = useAuth();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);

  const [menuOpen, setMenuOpen] = React.useState(null);

  const [userCollapseOpen, setUserCollapseOpen] = React.useState(false);

  const [adminUser, setAdminUser] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleClick = async () => {
    const params = {
      accessToken: user.accessToken,
    };

    const res = await dispatch(logoutAdministrator(params));

    // ここでAPIエラーの場合は止まる
    unwrapResult(res);

    await logout();
  };

  React.useEffect(async () => {
    const res = await dispatch(fetchMyAccount(user.email));

    const result = await unwrapResult(res);

    setAdminUser(result);
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Div100vh>
        <Box sx={{ display: 'flex', minHeight: '100%', minWidth: '100%' }}>
          <CssBaseline />
          <AppBar position="fixed" open={open} color="partsOne">
            <Toolbar
              sx={{
                pr: '24px',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon color="white" />
              </IconButton>
              <Box sx={{ flexGrow: 1 }}>
                <img height={35} src={logo} srcSet={logo} alt="PARTS ONE inc." loading="lazy" />
              </Box>
              <IconButton color="inherit" onClick={handleOpen}>
                <Avatar alt={adminUser?.admin_name ?? ''} src={adminUser?.image ?? ''} />
              </IconButton>
              <MenuPopover
                open={Boolean(menuOpen)}
                anchorEl={menuOpen}
                onClose={handleClose}
                sx={{
                  p: 0,
                  mt: 1.5,
                  ml: 0.75,
                  '& .MuiMenuItem-root': {
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                }}
              >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {user?.email}
                  </Typography>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
                {/* <Stack sx={{ p: 1 }}>
                  <NavLink
                    to=""
                  >
                    <MenuItem key="profile" onClick={handleClose}>
                      プロフィール
                    </MenuItem>
                  </NavLink>
                </Stack> */}
                <Divider sx={{ borderStyle: 'dashed' }} />
                <MenuItem onClick={handleClick} sx={{ m: 1 }}>
                  ログアウト
                </MenuItem>
              </MenuPopover>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <NavItem link={DASHBOARD_VIEW} icon={<DashboardIcon />} text="ダッシュボード" />
              <NavItem
                icon={<PeopleIcon />}
                subIcon={
                  userCollapseOpen ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowDownIcon style={{ transform: 'rotate(270deg)' }} />
                  )
                }
                text="ユーザー管理"
                onClick={() => setUserCollapseOpen(!userCollapseOpen)}
                subNav={
                  <Collapse in={userCollapseOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pl: 2 }}>
                      <NavItem
                        link={USER_LIST_VIEW}
                        icon={<CorporateFareIcon />}
                        text="ユーザー一覧"
                      />
                      <NavItem
                        link={USER_CREATE_VIEW}
                        icon={<DirectionsCarIcon />}
                        text="ユーザー一括登録"
                      />
                    </List>
                  </Collapse>
                }
              />

              <NavItem link={PRODUCT_LIST_VIEW} icon={<ShoppingCartIcon />} text="商品一覧" />
              <NavItem link={EXHIBIT_LIST_VIEW} icon={<AccountCircleIcon />} text="出品者" />
              <NavItem link={ORDER_LIST_VIEW} icon={<FactoryIcon />} text="発送・注文一覧" />

              <NavItem link={COMMENT_LIST_VIEW} icon={<CommentIcon />} text="コメント一覧" />

              <NavItem link={PAYMENTS_LIST_VIEW} icon={<PaymentIcon />} text="請求一覧" />

              <NavItem
                link={REQUEST_LIST_VIEW}
                icon={<AssignmentIcon />}
                text="部品お問い合わせ一覧"
              />

              <NavItem
                link={INQUIRIES_LIST_VIEW}
                icon={<ForwardToInboxIcon />}
                text="お問い合わせ一覧"
              />

              <NavItem
                link={ADMINISTRATOR_LIST_VIEW}
                icon={<AdminPanelSettingsIcon />}
                text="管理者一覧"
              />

              <NavItem
                link={ATRS_IMPORT_ERROR_LIST_VIEW}
                icon={<SimCardAlertIcon />}
                text="データ取込エラー"
              />

              <NavItem link={WITHDRAWAL_LIST_VIEW} icon={<ExitToAppIcon />} text="退会申請一覧" />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              backgroundColor: (theme) => theme.palette.grey[100],
              minHeight: '100%',
              minwidth: '100%',
              overflow: 'auto',
              marginLeft: open
                ? `${drawerWidth}px`
                : {
                    xs: '56px',
                    sm: '72px',
                  },
              transition: (theme) =>
                theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: open
                    ? theme.transitions.duration.enteringScreen
                    : theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            <Box sx={{ p: 2 }}>
              <Toolbar />
              {links.length ? <Breadcrumbs links={links} /> : null}
              <Box sx={{ minHeight: '72%' }}>{children}</Box>
            </Box>
            <Box
              sx={{
                mt: 'auto',
                whiteSpace: 'nowrap',
                position: 'sticky',
                left: '0',
                minWidth: '100%',
              }}
            >
              <Footer />
            </Box>
          </Box>
        </Box>
      </Div100vh>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ),
};

MainLayout.defaultProps = {
  links: [],
};
