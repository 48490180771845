/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { USER_BASE_URL } from 'utils/const';

export const fetchColor = createAsyncThunk('color/fetchColor', async () => {
  const res = await getApi(`${USER_BASE_URL}/master/colors/info?maker_id=1`);
  console.log(res);

  return {
    data: res.data,
  };
});

const colorAdapter = createEntityAdapter();

const colorSlice = createSlice({
  name: 'color',
  initialState: colorAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: colorAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchColor.fulfilled, (state, action) => {
      state.loading = false;
      colorAdapter.setAll(state, action.payload.data);
    });
  },
});

export const colorSelector = colorAdapter.getSelectors((state) => state.color);

export default colorSlice.reducer;
