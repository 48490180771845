import PropTypes from 'prop-types';
import * as React from 'react';

import { PublicLayout } from 'components/Layout';

export const Layout = ({ children }) => <PublicLayout>{children}</PublicLayout>;

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

Layout.defaultProps = {};
