import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

const Title = ({ children }) => (
  <Typography component="h2" variant="h6" color="primary" gutterBottom>
    {children}
  </Typography>
);

Title.propTypes = {
  children: PropTypes.node,
};
Title.defaultProps = {
  children: null,
};

export default Title;
