import { Navigate } from 'react-router-dom';

import { Apply } from 'features/auth/routes/Apply';
import { ResetPassword } from 'features/auth/routes/ResetPassword';
import { lazyImport } from 'utils/lazyImport';

import { AUTH_LOGIN_VIEW } from './urls';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/admin/account/reset_password',
    element: <ResetPassword />,
  },
  {
    path: '/admin/account/apply_activation',
    element: <Apply />,
  },
  {
    path: '/*',
    element: <Navigate to={AUTH_LOGIN_VIEW} />,
  },
];
