/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi, postApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 出品者を全件取得する
 */
export const fetchAllExhibitorUsers = createAsyncThunk(
  'requests/fetchAllExhibitorUsers',
  async (data, { dispatch }) => {
    const param = {
      per_page: data?.rowsPerPage,
      page: data?.page,
      exhibitor_user_name: data?.name,
      tel: data?.tel,
      merchant_code: data?.merchantCode,
      address: data?.address,
      exhibitor_type_atrs: data?.atrs ? 1 : 0,
      exhibitor_type_rebuild: data?.rebuild ? 1 : 0,
      exhibitor_type_partsone: data?.partsone ? 1 : 0,
      transaction_status_dealing: data?.trading ? 1 : 0,
      transaction_status_cancelled: data?.cancel ? 1 : 0,
    };

    try {
      const res = await getApi(`${BASE_URL}/exhibitor_user`, param);

      return res; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 出品者を取得する
 */
export const fetchExhibitorUser = createAsyncThunk(
  'requests/fetchExhibitorUser',
  async (id, { dispatch }) => {
    try {
      const res = await getApi(`${BASE_URL}/exhibitor_user/${id}`);

      console.log('requestAPI : 接続', res);

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 出品者を更新する
 */
export const updateExhibitorUser = createAsyncThunk(
  'requests/updateExhibitorUser',
  async (param, { dispatch }) => {
    try {
      const body = {
        exhibitor_user_name: param.name,
        tel: param.tel,
        transaction_status: param.tradingStatus,
        merchant_code: param.merchantCode,
        exhibitor_user_type: param.exhibitStatus,
        prefecture_id: param.prefecture,
        shipping_cutoff_time: param.date,
        shipping_information_s: param.ShippingInfoS,
        shipping_information_m: param.ShippingInfoM,
        shipping_information_l: param.ShippingInfoL,
        shipping_information_s_cutoff_time: param.ShippingDateS,
        shipping_information_m_cutoff_time: param.ShippingDateM,
        shipping_information_l_cutoff_time: param.ShippingDateL,
        memo: param.memo,
      };

      console.log('requestAPI', body);

      const res = await putApi(`${BASE_URL}/exhibitor_user/${param.exhibitorUserId}`, body);

      console.log('responseAPI', res);

      dispatch(
        enqueueSnackbar({
          message: '出品者情報を更新しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id: param.exhibitorUserId,
        order: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * 出品者を登録する
 */
export const createExhibitorUser = createAsyncThunk(
  'exhibitorUsers/createExhibitorUser',
  async (param, { dispatch }) => {
    try {
      const body = {
        exhibitor_user_name: param.name,
        tel: param.tel,
        transaction_status: param.tradingStatus,
        merchant_code: param.merchantCode,
        exhibitor_user_type: param.exhibitStatus,
        prefecture_id: param.prefecture,
        shipping_cutoff_time: param.date,
        shipping_information_s: param.ShippingInfoS,
        shipping_information_m: param.ShippingInfoM,
        shipping_information_l: param.ShippingInfoL,
        shipping_information_s_cutoff_time: param.ShippingDateS,
        shipping_information_m_cutoff_time: param.ShippingDateM,
        shipping_information_l_cutoff_time: param.ShippingDateL,
        memo: param.memo,
      };

      console.log('requestAPI', body);

      const res = await postApi(`${BASE_URL}/exhibitor_user`, body);

      console.log('responseAPI', res);

      dispatch(
        enqueueSnackbar({
          message: '出品者情報を登録しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id: param.exhibitorUserId,
        request: param,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const exhibitorUsersAdapter = createEntityAdapter({
  selectId: (e) => e.exhibitor_user_id,
});

export const exhibitorUserSlice = createSlice({
  name: 'exhibitorUsers',
  initialState: exhibitorUsersAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: exhibitorUsersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllExhibitorUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllExhibitorUsers.fulfilled, (state, action) => {
      exhibitorUsersAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchAllExhibitorUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchExhibitorUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchExhibitorUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchExhibitorUser.fulfilled, (state, action) => {
      exhibitorUsersAdapter.upsertOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(updateExhibitorUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateExhibitorUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateExhibitorUser.fulfilled, (state, action) => {
      exhibitorUsersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.request },
      });
      state.loading = false;
    });
    builder.addCase(createExhibitorUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createExhibitorUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createExhibitorUser.fulfilled, (state, action) => {
      exhibitorUsersAdapter.addOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.request },
      });
      state.loading = false;
    });
  },
});

export const exhibitorUserSelectors = exhibitorUsersAdapter.getSelectors(
  (state) => state.exhibitorUsers,
);

export default exhibitorUserSlice.reducer;
