import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

import Div100vh from 'react-div-100vh';

import logo from 'assets/images/appLogo.png';
import { Footer } from 'components/Elements/Footer/Footer';
import { useAuth } from 'lib/auth';

const AppBar = styled(
  MuiAppBar,
  {},
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const mdTheme = createTheme({
  palette: {
    type: 'light',
    partsOne: {
      main: '#3C4B64',
      background: '#F3F3F3',
    },
    white: {
      main: '#FFFFFF',
    },
  },
});

export const PublicLayout = ({ children, title }) => {
  const { logout, user } = useAuth();

  return (
    <ThemeProvider theme={mdTheme}>
      <Div100vh>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100%',
            minWidth: '100%',
          }}
        >
          <CssBaseline />
          <AppBar position="absolute" color="partsOne">
            <Toolbar
              sx={{
                pr: '24px',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <img height={35} src={logo} srcSet={logo} alt="PARTS ONE inc." loading="lazy" />
              </Box>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.grey[100],
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100%',
              minwidth: '100%',
              overflow: 'auto',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 8,
                  minHeight: '78%',
                }}
              >
                {children}
              </Box>
            </Box>
            <Box
              sx={{
                mt: 'auto',
                minWidth: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              <Footer />
            </Box>
          </Box>
        </Box>
      </Div100vh>
    </ThemeProvider>
  );
};

PublicLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
};

PublicLayout.defaultProps = {
  title: '',
};
