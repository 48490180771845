/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

export const fetchVehicleSpecification = createAsyncThunk(
  'info/fetchVehicleSpecification',
  async ({ classificationNo, carModelDesignationNo, carModelId }, { dispatch }) => {
    try {
      let paramStr = `${BASE_URL}/items/auto_identify_vehicle?`;
      if (classificationNo) paramStr += `classification_no=${classificationNo}&`;
      if (carModelDesignationNo) paramStr += `car_model_designation_no=${carModelDesignationNo}&`;
      if (carModelId) paramStr += `car_model_id=${carModelId}`;

      const res = await getApi(paramStr);
      console.log(res);
      dispatch(
        enqueueSnackbar({
          message: '型式指定番号と類別型式番号と一致するものを表示しました',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        data: res,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: '型式指定番号と類別型式番号と一致するものはありません。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const vehicleSpecificationAdapter = createEntityAdapter({
  selectId: (e) => e.id,
});

const vehicleSpecificationSlice = createSlice({
  name: 'vehicleSpecification',
  initialState: vehicleSpecificationAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: vehicleSpecificationAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVehicleSpecification.fulfilled, (state, action) => {
      state.loading = false;
      vehicleSpecificationAdapter.setAll(state, action.payload.data);
    });
  },
});

export const vehicleSpecificationSelector = vehicleSpecificationAdapter.getSelectors(
  (state) => state.vehicleSpecification,
);

export default vehicleSpecificationSlice.reducer;
