import { CircularProgress, Container } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';

import { applyActivationAdministrator } from 'stores/administratorSlice';

export const ApplyForm = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const { search } = useLocation();

  const queryString = search
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      // eslint-disable-next-line prefer-destructuring
      acc[a[0]] = a[1];

      return acc;
    }, {});

  useEffect(async () => {
    const params = {
      token: queryString.apply_activation_token,
    };

    const res = await dispatch(applyActivationAdministrator(params));

    unwrapResult(res);

    onSuccess();
  }, []);

  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

ApplyForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

ApplyForm.defaultProps = {};
