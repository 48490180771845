import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Stack, Box, Avatar, Typography, InputAdornment, Card } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import { Form } from 'components/Form/Form';
import { InputField } from 'components/Form/InputField';
import { resetPasswordAdministrator } from 'stores/administratorSlice';
import { STATUS_CODE_OK } from 'utils/const';
import { PASSWORD_LENGTH_PATTERN } from 'validation/validationPattern';

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required('パスワードを入力してください。')
      .matches(PASSWORD_LENGTH_PATTERN.regex, PASSWORD_LENGTH_PATTERN.message),
  })
  .required();

export const ResetPasswordForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queryString = search
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      // eslint-disable-next-line prefer-destructuring
      acc[a[0]] = a[1];

      return acc;
    }, {});

  const loading = useSelector((state) => state.administrators.loading);

  const onSubmit = async (data) => {
    const params = {
      password: data.password,
      resetPasswordToken: queryString.reset_password_token,
    };

    const res = await dispatch(resetPasswordAdministrator(params));

    if (res?.payload?.status === STATUS_CODE_OK) {
      onSuccess();
    }
  };

  const [visible, setVisible] = useState(false);

  const onVisible = () => {
    setVisible(!visible);
  };

  return (
    <Card
      sx={{
        p: 2,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        パスワード再設定
      </Typography>
      <Box noValidate sx={{ width: '100%', mt: 1 }}>
        <Form
          schema={schema}
          options={{
            shouldUnregister: true,
          }}
          onSubmit={onSubmit}
        >
          {({ register, formState }) => (
            <>
              <Stack spacing={2} sx={{ pt: 3, pb: 3 }}>
                <Typography variant="subtitle2">
                  新しいパスワードを入力し、送信してください。
                </Typography>
              </Stack>
              <InputField
                id="password"
                name="password"
                fullWidth
                label="新しいパスワード"
                type={visible ? 'text' : 'password'}
                error={formState.errors.password}
                registration={register('password')}
                helperText={formState.errors.password?.message}
                required
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" onClick={onVisible} sx={{ cursor: 'pointer' }}>
                      {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                type="submit"
                loading={loading}
              >
                送信する
              </LoadingButton>
            </>
          )}
        </Form>
      </Box>
    </Card>
  );
};

ResetPasswordForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

ResetPasswordForm.defaultProps = {};
