export const INITIAL_TABLE_PAGE_COUNT = 1;

export const BASE_URL = process.env.REACT_APP_API_URL;

export const USER_BASE_URL = process.env.REACT_APP_USER_API_URL;

export const USER_PAGE_URL = process.env.REACT_APP_USER_PAGE_URL;

export const STATUS_CODE_OK = 200;

export const STATUS_UN_AUTHORIZED = 401;

export const PREFECTURE_LIST = [
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' },
];

export const DELIVERY_COMPANY = [
  { value: 1, label: 'クロネコヤマト' },
  { value: 2, label: '佐川急便' },
  { value: 3, label: 'ゆうパック' },
  { value: 4, label: '西濃運輸' },
  { value: 5, label: '福山通運' },
  { value: 6, label: '第一貨物' },
  { value: 7, label: '久留米運送' },
  { value: 8, label: 'トールエクスプレス' },
  { value: 9, label: 'セイノースーパーエクスプレス' },
  { value: 10, label: 'ヤマト以外' },
];

export const CUTOFF_TIME = [
  { value: 1, label: '1時' },
  { value: 2, label: '2時' },
  { value: 3, label: '3時' },
  { value: 4, label: '4時' },
  { value: 5, label: '5時' },
  { value: 6, label: '6時' },
  { value: 7, label: '7時' },
  { value: 8, label: '8時' },
  { value: 9, label: '9時' },
  { value: 10, label: '10時' },
  { value: 11, label: '11時' },
  { value: 12, label: '12時' },
  { value: 13, label: '13時' },
  { value: 14, label: '14時' },
  { value: 15, label: '15時' },
  { value: 16, label: '16時' },
  { value: 17, label: '17時' },
  { value: 18, label: '18時' },
  { value: 19, label: '19時' },
  { value: 20, label: '20時' },
  { value: 21, label: '21時' },
  { value: 22, label: '22時' },
  { value: 23, label: '23時' },
  { value: 24, label: '24時' },
];

const {
  REACT_APP_AWS_ACCESS_KEY_ID: AWS_ACCESS_KEY_ID,
  REACT_APP_AWS_SECRET_ACCESS_KEY: AWS_SECRET_ACCESS_KEY,
  REACT_APP_AWS_DEFAULT_REGION: AWS_DEFAULT_REGION,
  REACT_APP_AWS_BUCKET: AWS_BUCKET,
  REACT_APP_AWS_USE_PATH_STYLE_ENDPOINT: AWS_USE_PATH_STYLE_ENDPOINT,
} = process.env;

export {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_DEFAULT_REGION,
  AWS_BUCKET,
  AWS_USE_PATH_STYLE_ENDPOINT,
};
