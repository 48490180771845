import { configureStore } from '@reduxjs/toolkit';

import administratorReducer from './administratorSlice';
import atrsImportErrorReducer from './atrsImportErrorSlice';
import commentReducer from './commentSlice';
import exhibitorUserReducer from './exhibitorUserSlice';
import inquiryReducer from './inquirySlice';
import notificationReducer from './notificationSlice';
import orderReducer from './orderSlice';
import paymentReducer from './paymentSlice';
import productMasterReducer from './productMasterSlice';
import productReducer from './productSlice';
import requestDetailReducer from './requestDetailSlice';
import requestReducer from './requestSlice';
import usersReducer from './userSlice';
import carModelReducer from './userStores/carModelsSlice';
import carPartsReducer from './userStores/carPartsSlice';
import carsInfoReducer from './userStores/carsInfoSlice';
import colorReducer from './userStores/colorSlice';
import makersReducer from './userStores/makerSlice';
import uzoneNumberReducer from './uzoneNumberSlice';
import vehicleSpecificationReducer from './vehicleSpecificationSlice';
import withdrawalReducer from './withdrawalSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    requests: requestReducer,
    requestDetail: requestDetailReducer,
    payments: paymentReducer,
    exhibitorUsers: exhibitorUserReducer,
    orders: orderReducer,
    notifications: notificationReducer,
    comments: commentReducer,
    products: productReducer,
    inquiries: inquiryReducer,
    atrsImportErrors: atrsImportErrorReducer,
    administrators: administratorReducer,
    productsMaster: productMasterReducer,
    withdrawal: withdrawalReducer,
    makers: makersReducer,
    carsInfo: carsInfoReducer,
    carModel: carModelReducer,
    carParts: carPartsReducer,
    color: colorReducer,
    vehicleSpecification: vehicleSpecificationReducer,
    uzoneNumber: uzoneNumberReducer,
  },
});

export default store;
