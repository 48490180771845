/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 請求情報を全件取得する
 */
export const fetchAllATRSImportErrors = createAsyncThunk(
  'atrsImportErrors/fetchAllATRSImportErrors',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
    };

    console.log('atrsImportErrorsAPI : param', param);

    // const res = await axios.get(`${BASE_URL}/atrsImportErrors`, {
    //   // ここに「params」というキー名でセットし、クエリパラメータを指定する
    //   params: param,
    // });
    try {
      const res = {
        data: {
          data: [
            {
              atrs_part_id: '00000001',
              member_store_id: '00000001',
              maker_code: 1,
              maker_name: 'トヨタ',
              car_name: 'プリウス',
              part_number: 'RHK001',
              part_name: 'RHK001',
            },
            {
              atrs_part_id: '00000002',
              member_store_id: '00000002',
              maker_code: 1,
              maker_name: 'トヨタ',
              car_name: 'プリウス',
              part_number: 'RHK001',
              part_name: 'RHK001',
            },
          ],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 4,
            per_page: '2',
            to: 2,
            total: 8,
          },
        },
      };
      console.log('atrsImportErrorsAPI response', res);

      return { data: res.data }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * コメント詳細を取得する
 */
export const fetchATRSImportError = createAsyncThunk(
  'atrsImportErrors/fetchATRSImportError',
  async (id) => {
    const res = await axios.get(`${BASE_URL}/atrsImportErrors/${id}`);

    console.log('atrsImportErrorAPI : 接続', res);

    return {
      id,
      atrsImportError: res.data.data,
    };
  },
);

/**
 * コメントステータスを更新する
 */
export const updateATRSImportError = createAsyncThunk(
  'atrsImportErrors/updateATRSImportError',
  async (param) => {
    const body = {
      status_code: param.status_code,
    };

    console.log('atrsImportErrorAPI', body);

    const res = await axios.put(`${BASE_URL}/atrsImportErrors/${param.item_id}`, body);

    console.log('responseAPI', res);

    return {
      id: param.item_id,
      atrsImportError: res.data.data,
    };
  },
);

/**
 * コメントステータスを更新する
 */
export const deleteATRSImportError = createAsyncThunk(
  'atrsImportErrors/deleteATRSImportError',
  async (id) => {
    console.log('atrsImportErrorAPI', id);

    const res = await axios.put(`${BASE_URL}/atrsImportErrors/${id}`);

    console.log('responseAPI', res);

    return {
      id,
    };
  },
);

const atrsImportErrorsAdapter = createEntityAdapter({
  selectId: (e) => e.atrs_part_id,
});

export const atrsImportErrorSlice = createSlice({
  name: 'atrsImportErrors',
  initialState: atrsImportErrorsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: atrsImportErrorsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllATRSImportErrors.fulfilled, (state, action) => {
      atrsImportErrorsAdapter.setAll(state, action.payload.data.data);
      state.loading = false;
    });
    builder.addCase(fetchAllATRSImportErrors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchATRSImportError.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchATRSImportError.fulfilled, (state, action) => {
      atrsImportErrorsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.atrsImportError },
      });
      state.loading = false;
    });
    builder.addCase(updateATRSImportError.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateATRSImportError.fulfilled, (state, action) => {
      atrsImportErrorsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.atrsImportError },
      });
      state.loading = false;
    });
    builder.addCase(deleteATRSImportError.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteATRSImportError.fulfilled, (state, action) => {
      atrsImportErrorsAdapter.deleteOne(state, {
        id: action.payload.id,
      });
      state.loading = false;
    });
  },
});

export const atrsImportErrorSelectors = atrsImportErrorsAdapter.getSelectors(
  (state) => state.atrsImportErrors,
);

export default atrsImportErrorSlice.reducer;
