/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { endOfDay } from 'date-fns';

import { getApi } from 'lib/axios';
import { formatDateTime } from 'lib/dateUtil';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 部品リクエストを全件取得する
 */
export const fetchAllRequests = createAsyncThunk(
  'requests/fetchAllRequests',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      user_name: data.userName,
      tel: data.tel,
      vehicle_no: data.vehicleNo,
      created_at_from: formatDateTime(data.requestDateFrom),
      created_at_to: formatDateTime(
        data.requestDateTo ? endOfDay(new Date(data.requestDateTo)) : '',
      ),
      status_completed: data.complete ? 1 : 0,
    };

    if (data.noReply) {
      param.status_unanswered = 1;
    }

    if (data.confirmationNow) {
      param.status_confirmation = 1;
    }

    try {
      const res = await getApi(`${BASE_URL}/part_inquiries`, param);

      return {
        data: res.data,
        total: res.meta.total,
      }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const requestsAdapter = createEntityAdapter({
  selectId: (e) => e.request_info_id,
});

export const requestSlice = createSlice({
  name: 'requests',
  initialState: requestsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: requestsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllRequests.rejected, (state) => {
      requestsAdapter.setAll(state, []);
      state.loading = false;
    });
    builder.addCase(fetchAllRequests.fulfilled, (state, action) => {
      requestsAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
  },
});

export const requestSelectors = requestsAdapter.getSelectors((state) => state.requests);

export default requestSlice.reducer;
