import { alpha, ListItemButton, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router';

export const ListItemStyle = styled(ListItemButton)(({ select, theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  // activeRoot
  ...select,
}));

export const NavItem = ({ link, text, icon, onClick, subNav, subIcon }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(link);
    }
  };

  const activeRootStyle = {
    color: theme.palette.grey[800],
    backgroundColor: alpha(theme.palette.info.light, 0.2),
  };

  return (
    <>
      <ListItemStyle
        onClick={handleOnClick}
        select={
          location.pathname === link
            ? {
                ...theme.typography.subtitle2,
                ...activeRootStyle,
                '&:hover': { ...activeRootStyle },
              }
            : {}
        }
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {subIcon ? <ListItemIcon sx={{ pl: 4 }}>{subIcon}</ListItemIcon> : null}
      </ListItemStyle>
      {subNav}
    </>
  );
};

NavItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  subNav: PropTypes.element,
  subIcon: PropTypes.element,
};

NavItem.defaultProps = {
  link: '/',
  onClick: undefined,
  subNav: undefined,
  subIcon: undefined,
};
