/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi, postApi } from 'lib/axios';
import { enqueueSnackbar } from 'stores/notificationSlice';
import { BASE_URL } from 'utils/const';

/**
 * ユーザーを全件取得する
 */
export const fetchAllUsers = createAsyncThunk('users/fetchAllUsers', async (data, { dispatch }) => {
  const param = {
    per_page: data.rowsPerPage,
    page: data.page,
    name: data.name,
    email: data.email,
    tel: data.tel,
  };

  if (data.corporation) {
    param.type_comp = 1;
  }

  if (data.individual) {
    param.type_indvl = 1;
  }

  if (data.soleProprietorship) {
    param.type_sole_comp = 1;
  }

  if (data.suspension) {
    param.sts_unapp = 1;
  }

  if (data.valid) {
    param.sts_app = 1;
  }

  if (data.authenticationInProgress) {
    param.sts_mail = 1;
  }

  try {
    const res = await getApi(`${BASE_URL}/users`, param);

    return { res }; // action.payloadとしてReducerで読み取れる
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: 'エラーが発生しました。',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );

    throw error;
  }
});

/**
 * ユーザーを取得する
 */
export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async ({ userId: id, userType: type }) => {
    // test

    const res = await getApi(`${BASE_URL}/users/${id}/${type}`);

    console.log('userAPI : 接続', res);

    return {
      id,
      user: res.data,
      order: res.data.order,
      meta: res.meta,
    };
  },
);

/**
 * ユーザー情報を更新する
 */

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ userId: id, data }, { dispatch }) => {
    const param = {
      user: id,
      name: data.name,
      email: data.email,
      tel: data.tel,
      fax: data.fax,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      other: data.building,
      status: data.status,
    };

    try {
      const res = await putApi(`${BASE_URL}/users/${id}/indivisual_user`, param);

      return {
        id,
        user: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 企業情報を更新する
 */

export const updateCompanyUser = createAsyncThunk(
  'users/updateComapnyUser',
  async ({ userId: id, data }, { dispatch }) => {
    const param = {
      user: id,
      name: data.name,
      email: data.email,
      tel: data.tel,
      fax: data.fax,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      other: data.other,
      status: data.status,
      cutoff_date_code: data.deadline,
      payment_date_code: data.paymentDate,
    };
    try {
      const res = await putApi(`${BASE_URL}/users/${id}/company_user`, param);

      return {
        id,
        user: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 個人配送先を登録する
 */
export const createShippingAddress = createAsyncThunk(
  'users/createShippingAddress',
  async ({ userId: id, data }) => {
    const params = {
      name: data.name,
      tel: data.tel,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      is_default: data.check,
    };

    const res = await postApi(`${BASE_URL}/users/${id}/user_shipping`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * 法人配送先を登録する
 */
export const createCompanyShippingAddress = createAsyncThunk(
  'users/createCompanyShippingAddress',
  async ({ userId: id, data }) => {
    const params = {
      name: data.name,
      tel: data.tel,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      is_default: data.check,
    };

    const res = await postApi(`${BASE_URL}/users/${id}/company_shipping`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * 個人配送先を更新する
 */
export const updateShipping = createAsyncThunk(
  'users/updateUpdateShipping',
  async ({ id, shippingId, data }) => {
    const params = {
      name: data.name,
      tel: data.tel,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      is_default: data.check,
    };

    const res = await putApi(`${BASE_URL}/users/${id}/user_shipping/${shippingId}`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * 法人配送先を更新する
 */
export const updateCompanyShipping = createAsyncThunk(
  'users/updateCompanyShipping',
  async ({ id, shippingId, data }) => {
    const params = {
      name: data.name,
      tel: data.tel,
      postal_code: data.postalCode,
      prefecture_id: data.prefectures,
      city: data.city,
      address: data.address,
      building: data.building,
      is_default: data.check,
    };

    const res = await putApi(`${BASE_URL}/users/${id}/company_shipping/${shippingId}`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * 法人担当者を登録する
 */
export const createPersonInCharge = createAsyncThunk(
  'user/createPersonInCharge',
  async ({ id, data }) => {
    const params = {
      position: data.position,
      name: data.name,
    };
    const res = await postApi(`${BASE_URL}/users/${id}/in_charge`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * ユーザーの一括登録する
 */
export const createMultipleUsers = createAsyncThunk(
  'users/csv_register',
  async (data, { dispatch, rejectWithValue }) => {
    const formData = new FormData();

    formData.append('csv_file', data);

    const params = {
      csv_file: formData,
    };

    try {
      const res = await postApi(`${BASE_URL}/users/csv_register`, params.csv_file);
      dispatch(
        enqueueSnackbar({
          message: 'CSVを一括登録しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        success: res?.success,
      };
    } catch (e) {
      if (rejectWithValue(e).payload.response.status === 500) {
        dispatch(
          enqueueSnackbar({
            message: 'サーバーでエラーが発生しました。',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }),
        );

        return rejectWithValue(e).payload.response.data;
      }

      return rejectWithValue(e).payload.response.data;
    }
  },
);

/**
 * 担当者を更新する
 */
export const updatePersonInCharge = createAsyncThunk(
  'user/updatePersonInCharge',
  async ({ id, chargeId, data }) => {
    const params = {
      position: data.position,
      name: data.name,
    };
    const res = await putApi(`${BASE_URL}/users/${id}/in_charge/${chargeId}`, params);

    return {
      user: res.data,
    };
  },
);

/**
 * 社内メモを登録する
 */
export const createAdminMemo = createAsyncThunk(
  'users/createMemo',
  async ({ userId, memo }, { dispatch }) => {
    const params = {
      admin_memo: memo,
    };

    console.log('params:', params);

    try {
      const res = await postApi(`${BASE_URL}/users/${userId}/user_admin_memo`, params);

      console.log('test', res);

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * 退会を承認する
 */
export const confirmToWithdraw = createAsyncThunk(
  'confirmToWithdraw',
  async ({ user, data }, { dispatch }) => {
    const params = {
      admin_reason: data.selectReason,
      admin_comment: data.withdrawalReason,
    };

    console.log('params', params);

    try {
      const res = await postApi(`${BASE_URL}/users/${user.user_id}/withdrawal`, params);
      console.log('res', res);
      dispatch(
        enqueueSnackbar({
          message: '退会を承認しました',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      return {
        user: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: '未完了の注文や決済が残っています',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const usersAdapter = createEntityAdapter({
  selectId: (e) => e.user_id,
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    addTag: usersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      usersAdapter.setAll(state, action.payload.res.data);
      state.loading = false;
    });
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload.user);
      state.loading = false;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      usersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.user },
      });
      state.loading = false;
    });
    builder.addCase(updateCompanyUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompanyUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCompanyUser.fulfilled, (state, action) => {
      usersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.user },
      });
      state.loading = false;
    });
    builder.addCase(createCompanyShippingAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCompanyShippingAddress.fulfilled, (state, action) => {
      usersAdapter.addOne(state, action.payload.user);
      state.loading = false;
    });
    builder.addCase(createShippingAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createShippingAddress.fulfilled, (state, action) => {
      usersAdapter.addOne(state, action.payload.user);
      state.loading = false;
    });
    builder.addCase(updateShipping.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateShipping.fulfilled, (state, action) => {
      usersAdapter.updateOne(state, action.payload.user);
    });
    builder.addCase(createAdminMemo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdminMemo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminMemo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPersonInCharge.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPersonInCharge.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createMultipleUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMultipleUsers.fulfilled, (state, action) => {
      state.loading = false;
      usersAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(confirmToWithdraw.fulfilled, (state, action) => {
      usersAdapter.setOne(state, action.payload.user);
      state.loading = false;
    });
    builder.addCase(confirmToWithdraw.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(confirmToWithdraw.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const userSelectors = usersAdapter.getSelectors((state) => state.users);

export default usersSlice.reducer;
