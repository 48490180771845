import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from 'components/Elements/Spinner';
import { lazyImport } from 'utils/lazyImport';

const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { UserList } = lazyImport(() => import('features/users'), 'UserList');
const { CreateUser } = lazyImport(() => import('features/users'), 'CreateUser');
const { UserDetail } = lazyImport(() => import('features/user'), 'UserDetail');
const { RequestsList } = lazyImport(() => import('features/requests'), 'RequestsList');
const { RequestDetail } = lazyImport(() => import('features/request'), 'RequestDetail');
const { InquiryList } = lazyImport(() => import('features/inquiries'), 'InquiryList');
const { InquiryDetail } = lazyImport(() => import('features/inquiry'), 'InquiryDetail');
const { ProductList } = lazyImport(() => import('features/products'), 'ProductList');
const { RebuildProductDetail } = lazyImport(
  () => import('features/product'),
  'RebuildProductDetail',
);
const { NonRebuiltProductDetail } = lazyImport(
  () => import('features/product'),
  'NonRebuiltProductDetail',
);
const { OrdersList } = lazyImport(() => import('features/orders'), 'OrdersList');
const { OrderDetail } = lazyImport(() => import('features/order'), 'OrderDetail');
const { PaymentsList } = lazyImport(() => import('features/payments'), 'PaymentsList');
const { PaymentDetail } = lazyImport(() => import('features/payment'), 'PaymentDetail');
const { ExhibitsList } = lazyImport(() => import('features/exhibits'), 'ExhibitsList');
const { ExhibitDetail } = lazyImport(() => import('features/exhibit'), 'ExhibitDetail');
const { AdministratorList } = lazyImport(
  () => import('features/administrators'),
  'AdministratorList',
);
const { AdministratorDetail } = lazyImport(
  () => import('features/administrator'),
  'AdministratorDetail',
);
const { CommentsList } = lazyImport(() => import('features/comments'), 'CommentsList');
const { CommentDetail } = lazyImport(() => import('features/comment'), 'CommentDetail');
const { ATRSImportErrorList } = lazyImport(
  () => import('features/atrsImportErrors'),
  'ATRSImportErrorList',
);
const { ProductsMaster } = lazyImport(() => import('features/productsMaster'), 'ProductsMaster');
const { ImportVehicleMaster } = lazyImport(
  () => import('features/importVehicleMaster'),
  'ImportVehicleMaster',
);
const { WithdrawalList } = lazyImport(() => import('features/withdrawals'), 'WithdrawalList');

const App = () => (
  <Suspense fallback={<Spinner />}>
    <Outlet />
  </Suspense>
);

const protectedRoutes = [
  {
    path: 'app',
    element: <App />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      {
        path: 'users',
        element: <UserList />,
      },
      {
        path: 'createUsers',
        element: <CreateUser />,
      },
      {
        path: 'users/:userId/:userType',
        element: <UserDetail />,
      },
      {
        path: 'inquiries',
        element: <InquiryList />,
      },
      {
        path: 'inquiries/:inquiryId',
        element: <InquiryDetail />,
      },
      {
        path: 'products',
        element: <ProductList />,
      },
      // リビルトのURL
      {
        path: 'products/rebuilt/:productId',
        element: <RebuildProductDetail />,
      },
      {
        path: 'products/rebuildCreate',
        element: <RebuildProductDetail />,
      },
      // リビルト以外のURL
      {
        path: 'products/non_rebuilt/:productId',
        element: <NonRebuiltProductDetail />,
      },
      {
        path: 'products/create',
        element: <NonRebuiltProductDetail />,
      },
      {
        path: 'requests',
        element: <RequestsList />,
      },
      {
        path: 'requests/:requestId',
        element: <RequestDetail />,
      },
      {
        path: 'orders',
        element: <OrdersList />,
      },
      {
        path: 'orders/:orderId',
        element: <OrderDetail />,
      },
      {
        path: 'payments',
        element: <PaymentsList />,
      },
      {
        path: 'payments/:paymentId',
        element: <PaymentDetail />,
      },
      {
        path: 'exhibits',
        element: <ExhibitsList />,
      },
      {
        path: 'exhibits/create',
        element: <ExhibitDetail />,
      },
      {
        path: 'exhibits/:exhibitId',
        element: <ExhibitDetail />,
      },
      {
        path: 'administrators',
        element: <AdministratorList />,
      },
      {
        path: 'administrators/:administratorId',
        element: <AdministratorDetail />,
      },
      {
        path: 'comments',
        element: <CommentsList />,
      },
      {
        path: 'comments/:itemId',
        element: <CommentDetail />,
      },
      {
        path: 'atrsImportErrors',
        element: <ATRSImportErrorList />,
      },
      {
        path: 'productsMaster',
        element: <ProductsMaster />,
      },
      {
        path: 'importVehicleMaster',
        element: <ImportVehicleMaster />,
      },
      {
        path: 'withdrawals',
        element: <WithdrawalList />,
      },
      { path: '', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];

export default protectedRoutes;
