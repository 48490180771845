/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { USER_BASE_URL } from 'utils/const';

/**
 * メーカーを全件取得する
 */

export const fetchAllMaker = createAsyncThunk('makers/fetchAllMaker', async () => {
  const res = await getApi(`${USER_BASE_URL}/master/makers/info`);
  console.log(res.data);

  return {
    makers: res.data,
  };
});

const makersAdapter = createEntityAdapter({
  selectId: (e) => e.id,
});

export const makersSlice = createSlice({
  name: 'makers',
  initialState: makersAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: makersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMaker.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAllMaker.fulfilled, (state, action) => {
      makersAdapter.setAll(state, action.payload.makers);
      state.loading = false;
    });
  },
});

export const makersSelector = makersAdapter.getSelectors((state) => state.makers);

export default makersSlice.reducer;
