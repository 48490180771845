import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { USER_BASE_URL } from 'utils/const';

export const fetchAllCarParts = createAsyncThunk('carParts/fetchAllCarParts', async () => {
  const res = await getApi(`${USER_BASE_URL}/master/parts/info`);
  console.log('carParts', res);

  return {
    data: res.data,
  };
});

const carPartsAdapter = createEntityAdapter({
  selectId: (e) => e.category_id,
});

/* eslint-disable no-param-reassign */
const carPartsSlice = createSlice({
  name: 'carParts',
  initialState: carPartsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: carPartsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCarParts.fulfilled, (state, action) => {
      state.loading = false;
      carPartsAdapter.setAll(state, action.payload.data);
    });
  },
});

export const carPartsSelector = carPartsAdapter.getSelectors((state) => state.carParts);

export default carPartsSlice.reducer;
