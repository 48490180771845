import { useNavigate } from 'react-router-dom';

import { AUTH_LOGIN_VIEW } from 'routes/urls';

import { Layout } from '../components/Layout';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <ResetPasswordForm onSuccess={() => navigate(AUTH_LOGIN_VIEW)} />
    </Layout>
  );
};
