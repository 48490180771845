import { Box, Typography, Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// ----------------------------------------------------------------------

export const Breadcrumbs = ({ links, activeLast }) => {
  const currentLink = links[links.length - 1].name;

  const listDefault = links.map((link) => <LinkItem key={link.name} link={link} />);

  const listActiveLast = links.map((link) => (
    <div key={link.name ?? ''}>
      {link.name !== currentLink ? (
        <LinkItem href={link.href ?? ''} name={link.name ?? ''} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      sx={{ mb: 2 }}
      separator={
        <Box
          sx={{
            opacity: 0.48,
          }}
        >
          {'>'}
        </Box>
      }
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  activeLast: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.element,
    }),
  ).isRequired,
};

Breadcrumbs.defaultProps = {
  activeLast: false,
};

// ----------------------------------------------------------------------

const LinkItem = ({ href, name }) => <NavLink to={href}>{name}</NavLink>;

LinkItem.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
};

LinkItem.defaultProps = {
  href: '',
  name: '',
};
