/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

/**
 * 通知を登録する
 */
export const enqueueSnackbar = createAsyncThunk('notification/enqueueSnackbar', (notification) => {
  const key = notification.options && notification.options.key;

  return {
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
});

/**
 * 通知を閉じる
 */
export const closeSnackbar = createAsyncThunk('notification/closeSnackbar', () => ({
  notification: {},
}));

/**
 * 通知を削除する
 */
export const removeSnackbar = createAsyncThunk('notification/removeSnackbar', (key) => ({
  notification: {
    key,
  },
}));

const notificationsAdapter = createEntityAdapter({
  selectId: (e) => e.id,
});

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState({}),
  reducers: {
    addTag: notificationsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(enqueueSnackbar.fulfilled, (state, action) => {
      notificationsAdapter.addOne(state, {
        id: action.payload.notification.key,
        ...action.payload.notification,
      });
    });
    builder.addCase(closeSnackbar.fulfilled, (state, action) => {
      notificationsAdapter.removeAll(state);
    });
    builder.addCase(removeSnackbar.fulfilled, (state, action) => {
      notificationsAdapter.removeOne(state, action.payload.notification.key);
    });
  },
});

export const notificationSelectors = notificationsAdapter.getSelectors(
  (state) => state.notifications,
);

export default notificationSlice.reducer;
