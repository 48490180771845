/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

import { deleteApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 出品者を全件取得する
 */
export const fetchAllProductMasters = createAsyncThunk(
  'requests/fetchAllProductMasters',
  async (data, { dispatch }) => {
    const param = {};

    // if (data.noReply) {
    //   param.status_unanswered = 1;
    // }

    // if (data.confirmationNow) {
    //   param.status_confirmation = 1;
    // }

    // if (data.completion) {
    //   param.status_completed = 1;
    // }

    console.log('requestsAPI : param', param);

    try {
      // const res = await axios.get(`${BASE_URL}/exhibitor_user`, {
      //   // ここに「params」というキー名でセットし、クエリパラメータを指定する
      //   params: param,
      // });

      // console.log('requestsAPI response', res);

      const res = {
        data: [
          {
            product_id: '',
            product_type_name: '',
            product_name: '',
            summary: '',
            product_get_flag: '',
            get_off_count: '',
            guid_flag: '',
            active_status: '',
          },
        ],
      };

      return { data: res.data }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 商品を削除する
 */
export const deleteProducts = createAsyncThunk(
  'requests/deleteProduct',
  async (data, { dispatch }) => {
    try {
      const res = await deleteApi(`${BASE_URL}/items/${data.item_id}`);

      dispatch(
        enqueueSnackbar({
          message: '商品が正常に削除されました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res.data;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: '商品の削除中にエラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);
/**
 * 出品者を更新する
 */
export const updateActiveStatus = createAsyncThunk(
  'requests/updateProductMaster',
  async (param) => {
    const body = {
      status_code: param.status_code,
      request_memo: param.request_memo,
    };

    console.log('requestAPI', body);

    const res = await axios.put(`${BASE_URL}/exhibitor_user/${param.requestId}`, body);

    console.log('responseAPI', res);

    return {
      id: param.product_id,
      request: param,
    };
  },
);

const productMastersAdapter = createEntityAdapter({
  selectId: (e) => e.request_id,
});

export const productMasterSlice = createSlice({
  name: 'productsMaster',
  initialState: productMastersAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: productMastersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProductMasters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllProductMasters.fulfilled, (state, action) => {
      productMastersAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchAllProductMasters.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const productMasterSelectors = productMastersAdapter.getSelectors(
  (state) => state.productsMaster,
);

export default productMasterSlice.reducer;
