import { useNavigate } from 'react-router';

import { AUTH_LOGIN_VIEW } from 'routes/urls';

import { ApplyForm } from '../components/ApplyForm';
import { Layout } from '../components/Layout';

export const Apply = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <ApplyForm
        onSuccess={() => {
          navigate(AUTH_LOGIN_VIEW);
        }}
      />
    </Layout>
  );
};
