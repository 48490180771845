/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { endOfDay, format, startOfDay } from 'date-fns';

import { getApi, putApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * お問い合わせを全件取得する
 */
export const fetchAllInquiries = createAsyncThunk(
  'inquiries/fetchAllInquiries',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      user_name: data.name,
      email: data.email,
      created_at_from: data.dateFrom
        ? format(startOfDay(new Date(data.dateFrom)), 'yyyy-MM-dd HH:mm')
        : undefined,
      created_at_to: data.dateTo
        ? format(endOfDay(new Date(data.dateTo)), 'yyyy-MM-dd HH:mm')
        : undefined,
      inquiry_type_code: data.classification,
      status_unanswered: data.unanswered ? 1 : 0,
      status_confirmation: data.confirmationInprogress ? 1 : 0,
      status_completed: data.completion ? 1 : 0,
    };

    try {
      const res = await getApi(`${BASE_URL}/inquiries`, param);

      return res; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * お問い合わせ詳細を取得する
 *  * */
export const fetchInquiry = createAsyncThunk('inquiries/fetchInquiry', async (id) => {
  const res = await getApi(`${BASE_URL}/inquiries/${id}`);

  console.log('inquiryAPI: 接続', res);

  return res;
});

/**
 * お問い合わせを更新する
 */
export const updateInquiry = createAsyncThunk(
  'inquiries/updateInquiry',
  async (param, { dispatch }) => {
    const body = {
      status_code: param.status_code,
      admin_memo: param.inquiry_memo,
    };

    try {
      console.log('inquiryAPI params', body);
      const res = await putApi(`${BASE_URL}/inquiries/${param.inquiryId}`, body);

      console.log('inquiryAPI', res);

      return {
        id: res.inquiryId,
        inquiry: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const inquiriesAdapter = createEntityAdapter({
  selectId: (e) => e.inquiry_id,
});

export const inquirySlice = createSlice({
  name: 'inquiries',
  initialState: inquiriesAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: inquiriesAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllInquiries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllInquiries.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAllInquiries.fulfilled, (state, action) => {
      inquiriesAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchInquiry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInquiry.fulfilled, (state, action) => {
      inquiriesAdapter.upsertOne(state, { ...action.payload.data });
      state.loading = false;
    });
    builder.addCase(updateInquiry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInquiry.fulfilled, (state, action) => {
      inquiriesAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.inquiry },
      });
      state.loading = false;
    });
  },
});

export const inquirySelectors = inquiriesAdapter.getSelectors((state) => state.inquiries);

export default inquirySlice.reducer;
