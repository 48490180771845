/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi, postApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 注文/発送情報を全件取得する
 */
export const fetchAllOrders = createAsyncThunk(
  'orders/fetchAllOrders',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      order_id: data.orderId,
      recipient_name: data.userName,
      created_at: data.orderDate,
      admin_id: data.adminId,
      shipping_digest_code: data.shippingDigestCode,
    };

    try {
      const res = await getApi(
        `${BASE_URL}/orders`,
        // ここに「params」というキー名でセットし、クエリパラメータを指定する
        param,
      );

      return {
        data: res.data,
        total: res.meta.total,
        adminList: res.meta.admin_list,
        shippingDigestCode: res.meta.shipping_digest_list,
      }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * 注文/発送詳細を取得する
 */
export const fetchOrder = createAsyncThunk('orders/fetchOrder', async (id) => {
  const res = await getApi(`${BASE_URL}/orders/${id}`);

  return {
    data: res.data,
    admin_list: res.meta.admin_list,
    payment_status_list: res.meta.payment_status_list,
    shipping_company_list: res.meta.shipping_company_list,
    shipping_status_list: res.meta.shipping_status_list,
  };
});

/**
 * 注文対応状況を更新する
 */
export const updateOrderHandleStatus = createAsyncThunk(
  'orders/updateOrderHandleStatus',
  async (param) => {
    const params = {
      payment_status_code: param.paymentStatusCode,
      admin_id: param.adminId,
    };

    const res = await putApi(`${BASE_URL}/orders/${param.orderId}`, params);

    return {
      id: param.orderId,
      order: res.data,
    };
  },
);

/**
 * 注文/発送情報を更新する
 */
export const updateOrderItem = createAsyncThunk('orders/updateOrderItem', async (param) => {
  const params = {
    order_id: param.orderId,
    shipping_status_code: param.shippingStatusCode,
    shipping_number: param.shippingNumber,
    shipping_company_id: param.shippingCompanyId,
    shipping_company_name: param.shippingCompanyName,
  };

  const res = await putApi(`${BASE_URL}/orders/order_item/${param.orderItemId}`, params);

  return {
    id: param.orderId,
    order: res.data.data,
  };
});

/**
 * メモを登録する
 */
export const createAdminMemo = createAsyncThunk(
  'users/createMemo',
  async ({ orderId: id, memo }, { dispatch }) => {
    try {
      const params = { admin_id: 1, admin_memo: memo };
      const res = await postApi(`${BASE_URL}/orders/${id}`, params);

      dispatch(
        enqueueSnackbar({
          message: 'コメントを追加しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id,
        user: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const ordersAdapter = createEntityAdapter({
  selectId: (e) => e.order_id,
});

export const orderSlice = createSlice({
  name: 'orders',
  initialState: ordersAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: ordersAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
      ordersAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchAllOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      ordersAdapter.upsertOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(createAdminMemo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdminMemo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminMemo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrderHandleStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrderHandleStatus.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrderHandleStatus.fulfilled, (state, action) => {
      ordersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.order },
      });
      state.loading = false;
    });
    builder.addCase(updateOrderItem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrderItem.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateOrderItem.fulfilled, (state, action) => {
      ordersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.order },
      });
      state.loading = false;
    });
  },
});

export const orderSelectors = ordersAdapter.getSelectors((state) => state.orders);

export default orderSlice.reducer;
