/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 請求情報を全件取得する
 */
export const fetchAllPayments = createAsyncThunk(
  'payments/fetchAllPayments',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      user_name: data.name,
      created_at: data.date,
      tel: data.tel,
    };

    if (data.card) {
      param.type_credit_card = 1;
    }

    if (data.invoice) {
      param.type_invoice = 1;
    }
    if (data.unclaimed) {
      param.status_unclaimed = 1;
    }
    if (data.notPayment) {
      param.status_in_charge = 1;
    }
    if (data.paid) {
      param.status_claimed = 1;
    }

    try {
      const res = await getApi(`${BASE_URL}/charges`, param);

      return {
        data: res.data,
        total: res.meta.total,
      }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * 請求詳細を取得する
 */
export const fetchPayment = createAsyncThunk('payments/fetchPayment', async (id) => {
  const res = await getApi(`${BASE_URL}/charges/${id}`);

  console.log('paymentAPI : 接続', res);

  return {
    id,
    payment: res,
  };
});

/**
 * 請求情報を更新する
 */
export const updatePayment = createAsyncThunk('payments/updatePayment', async (param) => {
  const params = {
    status_code: param.status_code,
    admin_memo: param.memo,
  };

  console.log('paymentAPI', params);

  const res = await putApi(`${BASE_URL}/charges/${param.paymentId}`, params);

  console.log('responseAPI', res);

  return {
    id: param.paymentId,
    payment: res.data,
  };
});

const paymentsAdapter = createEntityAdapter({
  selectId: (e) => e.order_id,
});

export const paymentSlice = createSlice({
  name: 'payments',
  initialState: paymentsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: paymentsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPayments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllPayments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAllPayments.fulfilled, (state, action) => {
      paymentsAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchPayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPayment.fulfilled, (state, action) => {
      paymentsAdapter.upsertOne(state, { ...action.payload.payment });
      state.loading = false;
    });
    builder.addCase(updatePayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePayment.fulfilled, (state, action) => {
      paymentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.payment },
      });
      state.loading = false;
    });
  },
});

export const paymentSelectors = paymentsAdapter.getSelectors((state) => state.payments);

export default paymentSlice.reducer;
