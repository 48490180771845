/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi, postApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 商品を全件取得する
 */
export const fetchAllProducts = createAsyncThunk(
  'requests/fetchAllProducts',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      car_model_name: data.modelNumber,
      manufacturer_parts_no: data.partsNumber,
      atrs_parts_control_number: data.ATRSPartsNumber,
      uzone_parts_no: data.uZonePartsNumber,
      exhibitor_user_name: data.exhibitor_user,
    };

    try {
      const res = await getApi(
        `${BASE_URL}/items`,
        // ここに「params」というキー名でセットし、クエリパラメータを指定する
        param,
      );

      return {
        data: res.data,
        total: res.meta.total,
      }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );
      throw error;
    }
  },
);

/**
 * リビルト品以外の商品を取得する
 */
export const fetchNonRebuiltProduct = createAsyncThunk(
  'requests/fetchNonRebuiltProduct',
  async (id) => {
    const res = await getApi(`${BASE_URL}/items/${id}`);

    console.log('requestAPI : 接続', res);

    return { data: res.data };
  },
);

/**
 * リビルト品の商品を取得する
 */
export const fetchRebuiltProduct = createAsyncThunk('requests/fetchRebuiltProduct', async (id) => {
  const res = await getApi(`${BASE_URL}/items/rebuilt/${id}`);

  console.log('requestAPI : 接続', res);

  return { data: res.data };
});

/**
 * リビルト品以外の商品を更新する
 */
export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (data, { dispatch }) => {
    try {
      const itemImages = data?.file?.map((location) => ({
        Location: location,
        ETag: '\\97669b1c7265cd25f2c0c53f350204ab\\',
        key: '2/ダウンロード.jpeg',
        Bucket: 'upload-avatar-test',
      }));
      const params = {
        item_name: `${data?.carInfo?.maker_name} ${data?.carInfo?.name} ${data?.carsModel?.name} ${data?.selectedParts?.name}`,
        car_model_designation_no: data?.data?.car_model_designation_no,
        car_model_classification: data?.data?.classification_no,
        car_model_name: data?.carsModel?.name,
        car_model_id: data?.carsModel?.id,
        maker_id: data?.data?.maker,
        car_id: data?.data?.car_name,
        vehicle_no: data?.data?.vehicle_no,
        model_year: data?.data?.model_year?.substring(0, 4),
        model_month: data?.data?.model_year?.substring(5, 7),
        color: data?.showList,
        trim: data?.data?.trim,
        grade_name: data?.data?.grade,
        engine: data?.engine?.id,
        mission: data?.data?.mission,
        drive_method: data?.data?.drive_method,
        frame: data?.data?.frame,
        mileage: data?.data?.mileage,
        parts_classification: data?.data?.parts_classification,
        size: data?.data?.size,
        quality_rank: data?.data?.quality_rank,
        parts_manufacturer_parts_no: data?.data?.parts_manufacturer_parts_no,
        parts_no: String(data?.data?.parts_number),
        parts_id: String(data?.selectedParts?.id),
        body_parts_number: data?.data?.body_parts_number,
        clutch_parts_no: data?.data?.clutch_parts_number,
        rebuilt_manufacturer_parts_no: data.data?.rebuilt_parts_number,
        atrs_parts_control_number: data?.data?.atrs_parts_control_number,
        quantity: data?.data?.quantity,
        status: data?.data?.status,
        parts_information: data?.data?.partInfo,
        injury_information: data?.data?.partStatus,
        color_id: data?.showList,
        parts_cost: data?.data?.parts_cost,
        sales_price: data?.data?.sales_price,
        guarantee_flag: data?.data?.is_guaranteed,
        guarantee: data?.data?.guarantee,
        core_return: data?.data?.core_return,
        exhibitor_user_id: data?.exhibitorUserId,
        shipping_from_prefecture: data?.data?.prefecture,
        is_yamato: data?.data?.delivery_company === 1 ? 1 : 0,
        shipping_cutoff_time: data?.data?.shipping_cutoff_time,
        item_images: itemImages,
      };

      const res = await putApi(`${BASE_URL}/items/${data.id}`, params);

      dispatch(
        enqueueSnackbar({
          message: '商品情報の更新が完了しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id: data.id,
        products: res.data.data,
      };
    } catch (error) {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * リビルト以外を登録する
 */
export const createNonRebuilt = createAsyncThunk('products/createNonRebuilt', async (data) => {
  const itemImages =
    data?.file?.length > 0
      ? data?.file?.map((location) => ({
          Location: location,
          ETag: '\\97669b1c7265cd25f2c0c53f350204ab\\',
          key: '2/ダウンロード.jpeg',
          Bucket: 'upload-avatar-test',
        }))
      : [
          {
            ETag: '\\97669b1c7265cd25f2c0c53f350204ab\\',
            Location:
              'https://upload-avatar-test.s3.ap-northeast-1.amazonaws.com/1/%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99.jpeg',
            key: '1/ダウンロード.jpeg',
            Key: '1/ダウンロード.jpeg',
            Bucket: 'upload-avatar-test',
          },
        ];
  console.log(data);
  const params = {
    item_name: `${data?.carInfo?.maker_name} ${data?.carInfo?.name} ${data?.carsModel?.name} ${data?.selectedParts?.name}`,
    car_model_designation_no: data?.data?.car_model_designation_no,
    car_model_classification: data?.data?.classification_no,
    car_model_name: data?.carsModel?.name,
    car_model_id: data?.carsModel?.id,
    maker_id: data?.data?.maker,
    car_id: data?.data?.car_name,
    vehicle_no: data?.data?.vehicle_no,
    model_year: data?.data?.model_year?.substring(0, 4),
    model_month: data?.data?.model_year?.substring(5, 7),
    color: data?.showList,
    trim: data?.data?.trim,
    grade_name: data?.data?.grade,
    engine: data?.engine?.id,
    mission: data?.data?.mission,
    drive_method: data?.data?.drive_method,
    frame: data?.data?.frame,
    mileage: data?.data?.mileage,
    // data.data.registrationDate
    parts_classification: data?.data?.parts_classification,
    size: data?.data?.size,
    quality_rank: data?.data?.quality_rank,
    parts_manufacturer_parts_no: data?.data?.parts_manufacturer_parts_no,
    parts_no: String(data?.data.parts_number),
    parts_id: String(data?.selectedParts?.id),
    body_parts_number: data?.data?.body_parts_number,
    clutch_parts_no: data?.data?.clutch_parts_number,
    rebuilt_manufacturer_parts_no: data.data?.rebuilt_parts_number,
    atrs_parts_control_number: data?.data?.atrs_parts_control_number,
    quantity: data?.data?.quantity,
    status: data?.data?.status,
    parts_information: data?.data?.partInfo,
    injury_information: data?.data?.partStatus,
    color_id: data?.showList,
    parts_cost: data?.data?.parts_cost,
    sales_price: data?.data?.sales_price,
    guarantee_flag: data?.data?.is_guaranteed,
    guarantee: data?.data?.guarantee,
    core_return: data?.data?.core_return,
    exhibitor_user_id: data?.exhibitorUserId,
    shipping_from_prefecture: data?.data?.prefecture,
    is_yamato: data?.data?.delivery_company === 1 ? 1 : 0,
    shipping_cutoff_time: data?.data?.shipping_cutoff_time,
    postage: data?.data?.postage,
    item_images: itemImages,
  };

  console.log('requestAPI', params);

  const res = await postApi(`${BASE_URL}/items`, params);

  console.log('responseAPI', res);

  return {
    products: res.data,
  };
});

/**
 * リビルト品を登録する
 */
export const createRebuiltProduct = createAsyncThunk(
  'products/createRebuiltProduct',
  async (data, dispatch) => {
    const itemImages =
      data?.file?.length > 0
        ? data?.file?.map((location) => ({
            Location: location,
            ETag: '\\97669b1c7265cd25f2c0c53f350204ab\\',
            key: '2/ダウンロード.jpeg',
            Bucket: 'upload-avatar-test',
          }))
        : [
            {
              ETag: '\\97669b1c7265cd25f2c0c53f350204ab\\',
              Location:
                'https://upload-avatar-test.s3.ap-northeast-1.amazonaws.com/1/%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%88%E3%82%99.jpeg',
              key: '1/ダウンロード.jpeg',
              Key: '1/ダウンロード.jpeg',
              Bucket: 'upload-avatar-test',
            },
          ];
    const params = {
      item_name: `${data?.carInfo?.maker_name} ${data?.carInfo?.name} ${data?.carsModel?.name} ${data?.selectedParts?.name}`,
      item_control_no: data.data?.item_no,
      parts_no: data.data?.Uzone_no,
      parts_manufacturer_no: data.data?.true_code,
      car_model_name: data.carsModel?.name,
      car_model_id: data.carsModel?.id,
      maker: data.data?.maker,
      car_name: data.data?.carsCategory,
      car_model: data.data?.carModel,
      parts_id: data?.selectedParts?.id,
      // リビルトは0、中古は1
      parts_classification: 0,
      size: 'S',
      body_parts_no: data.data?.car_no,
      clutch_parts_no: data.data?.clutch,
      rebuilt_manufacturer_parts_no: data.data?.rebuild_no,
      parts_information: data.data?.partsInfo,
      scheduled_delivery_date: data.data?.deliver,
      parts_cost: data.data?.partsPurchasingPrice,
      sales_price: data.data?.basePrice,
      shipping_cutoff_time: data.data?.shippingDeadline,
      guarantee_flag: data.data?.guarantee,
      guarantee: data.data?.guaranteeContent,
      core_return: data.data?.core_return,
      quantity: data.data?.quantity,
      status: data.data?.status,
      rebuilt_maker_id: data.data?.seller,
      is_yamato: data.data?.deliveryInfo === 1 ? 1 : 0,
      item_images: itemImages,
    };

    try {
      const res = await postApi(`${BASE_URL}/items/rebuilt`, params);

      dispatch(
        enqueueSnackbar({
          message: '商品を追加しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        products: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

export const updateRebuildProduct = createAsyncThunk(
  'products/updateRebuildProduct',
  async (data, { dispatch }) => {
    try {
      console.log('data', data);

      const params = {
        sales_price: data.basePrice,
        shipping_from_prefecture: data.prefecture,
        scheduled_delivery_date: data.deliver,
        shipping_cutoff_time: data.shippingDeadline,
        status: 1,
        core_return: 1,
        rebuilt_maker_id: data.seller,
        quantity: 1,
        guarantee: data.guaranteeContent,
        guarantee_flag: data.guarantee,
        parts_cost: data.partsPurchasingPrice,
        is_yamato: 1,
        item_images: [],
      };

      console.log('requestupdateAPI', params);

      const res = await putApi(`${BASE_URL}/items/rebuilt/${data.id}`, params);

      console.log('responseAPI', res);

      dispatch(
        enqueueSnackbar({
          message: '商品情報の更新が完了しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id: data.id,
        products: res.data.data,
      };
    } catch (error) {
      console.error(error);
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * コメントを作成する
 */
export const createComment = createAsyncThunk(
  'products/createComment',
  async ({ itemId, comment }, { dispatch }) => {
    try {
      const params = {
        item_id: itemId,
        user_id: 1,
        comment,
      };

      const res = await postApi(`${BASE_URL}/items/comment `, params);

      dispatch(
        enqueueSnackbar({
          message: 'コメントを追加しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        data: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

export const fetchRebuiltStatus = createAsyncThunk('requests/fetchRebuiltStatus', async () => {
  const res = await getApi(`${BASE_URL}/items/options/rebuilt`);

  console.log('requestAPI : 接続', res);

  return { list: res.meta };
});

export const fetchNonRebuiltStatus = createAsyncThunk(
  'requests/fetchNonRebuiltStatus',
  async () => {
    const res = await getApi(`${BASE_URL}/items/options/non_rebuilt`);

    console.log('requestAPI : 接続', res);

    return { list: res.meta };
  },
);

const productsAdapter = createEntityAdapter({
  selectId: (e) => e.id,
});

export const productSlice = createSlice({
  name: 'products',
  initialState: productsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: productsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, action) => {
      productsAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchAllProducts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchNonRebuiltProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNonRebuiltProduct.fulfilled, (state, action) => {
      productsAdapter.upsertOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchRebuiltProduct.fulfilled, (state, action) => {
      productsAdapter.upsertOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchRebuiltProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProduct.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      productsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload.products,
      });
      state.loading = false;
    });
    builder.addCase(createNonRebuilt.fulfilled, (state, action) => {
      productsAdapter.addOne(state, action.payload.products);
      state.loading = false;
    });
    builder.addCase(createNonRebuilt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createRebuiltProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createRebuiltProduct.fulfilled, (state, action) => {
      productsAdapter.addOne(state, action.payload.products);
      state.loading = false;
    });
    builder.addCase(createComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createComment.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

export const productSelectors = productsAdapter.getSelectors((state) => state.products);

export default productSlice.reducer;
