import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

export const InputField = (props) => {
  const {
    type,
    label,
    registration,
    error,
    helperText,
    id,
    name,
    autoComplete,
    autoFocus,
    disabled,
    multiline,
    defaultValue,
    rows,
    size,
    align,
    placeholder,
    variant,
    InputProps,
    required,
    validation,
    editable,
    onBlur,
  } = props;

  return (
    <TextField
      id={id}
      name={name}
      margin="normal"
      fullWidth
      label={required ? `${label}（必須）` : label}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...registration}
      error={error}
      helperText={helperText}
      placeholder={placeholder || label}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      multiline={multiline}
      defaultValue={defaultValue}
      rows={rows}
      InputLabelProps={{
        shrink: true,
      }}
      size={size}
      inputProps={{
        ...validation,
        style: {
          textAlign: align,
        },
      }}
      onBlur={onBlur}
      variant={variant}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={InputProps}
      required={required}
      sx={{
        '& .Mui-disabled': {
          color: '#000',
          '-webkit-text-fill-color': 'unset!important',
        },
        '& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled': {
          color: '#000',
        },
        ...(editable && {
          '& .MuiInputBase-root': {
            backgroundColor: '#ffeaea',
            borderRadius: 1,
          },
        }),
      }}
    />
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  registration: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  defaultValue: PropTypes.string,
  size: PropTypes.string,
  align: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  editable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  onBlur: PropTypes.func,
};

InputField.defaultProps = {
  type: 'text',
  label: '',
  error: false,
  helperText: '',
  autoComplete: '',
  autoFocus: false,
  disabled: false,
  multiline: false,
  defaultValue: '',
  registration: {},
  rows: undefined,
  size: 'medium',
  align: 'left',
  placeholder: '',
  variant: 'outlined',
  InputProps: undefined,
  required: false,
  validation: {},
  editable: false,
  onBlur: undefined,
};
