import Axios from 'axios';

import { STATUS_UN_AUTHORIZED } from 'utils/const';

const axios = Axios.create();

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === STATUS_UN_AUTHORIZED) {
      localStorage.setItem('accessToken', '');
      window.location.assign(window.location.origin);
    }

    return Promise.reject(error);
  },
);

let accessToken = '';

export const setApiToken = (token) => {
  accessToken = token;
};

export const getApi = async (url, params) => {
  const res = await axios.get(url, {
    // ここに「params」というキー名でセットし、クエリパラメータを指定する
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export const putApi = async (url, params) => {
  const res = await axios.put(
    url,
    // ここに「params」というキー名でセットし、クエリパラメータを指定する
    params,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return res;
};

export const postApi = (url, params) => {
  const res = axios.post(url, params, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export const deleteApi = async (url, params) => {
  const res = await axios.delete(url, {
    data: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res;
};

export default axios;
