/* eslint-disable no-param-reassign */
import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { USER_BASE_URL } from 'utils/const';

// 型式（車種の後のやつ）
export const fetchCarModel = createAsyncThunk('carModel/fetchCarModel', async (id) => {
  const res = await getApi(`${USER_BASE_URL}/master/car_models/info?car_id=${id}`);

  return {
    data: res.data,
  };
});

const carModelAdapter = createEntityAdapter();

const carModelSlice = createSlice({
  name: 'carModel',
  initialState: carModelAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: carModelAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarModel.fulfilled, (state, action) => {
      state.loading = false;
      carModelAdapter.setAll(state, action.payload.data);
    });
  },
});

export const carModelSelector = carModelAdapter.getSelectors((state) => state.carModel);

export default carModelSlice.reducer;
