/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import axios, { getApi, putApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 管理者情報を全件取得する
 */
export const fetchAllAdministrators = createAsyncThunk(
  'administrators/fetchAllAdministrators',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      status_mail_unchecked: data.mailUnchecked ? '1' : '0',
      status_approval_pending: data.approvalPending ? '1' : '0',
      status_valid: data.valid ? '1' : '0',
      status_invalid: data.invalid ? '1' : '0',
      admin_name: data.name,
      email: data.email,
    };

    try {
      const res = await getApi(`${BASE_URL}/admins`, param);

      return res; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * 管理者詳細を取得する
 */
export const fetchAdministrator = createAsyncThunk(
  'administrators/fetchAdministrator',
  async (id) => {
    const res = await getApi(`${BASE_URL}/admins/${id}`);

    console.log(res);

    return {
      id,
      admin: res.data,
    };
  },
);

/**
 * 自身のアカウントを取得する
 */
export const fetchMyAccount = createAsyncThunk('administrators/fetchMyAccount', async (email) => {
  const param = {
    admin_name: '',
    email,
    page: 1,
    per_page: 5,
    status_approval_pending: '1',
    status_invalid: '0',
    status_mail_unchecked: '1',
    status_valid: '1',
  };

  const res = await getApi(`${BASE_URL}/admins`, param);

  return res.data?.[0]; // action.payloadとしてReducerで読み取れる
});

/**
 * アカウント登録
 */
export const createAdministrator = createAsyncThunk(
  'user/createAdministrator',
  async (param, { dispatch }) => {
    try {
      const params = {
        image: param.image,
        email: param.email,
        admin_name: param.name,
        password: param.password,
        tel: param.tel,
        other: param.other,
      };

      const res = await axios.post(`${BASE_URL}/account/temporary_registration`, params);

      dispatch(
        enqueueSnackbar({
          message: 'アカウント仮登録が完了しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * ログイン
 */
export const loginAdministrator = createAsyncThunk(
  'administrators/loginAdministrator',
  async (param, { dispatch }) => {
    try {
      const params = {
        email: param.email,
        password: param.password,
      };

      const res = await axios.post(`${BASE_URL}/account/login`, params);

      dispatch(
        enqueueSnackbar({
          message: 'ログインに成功しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * ログアウト
 */
export const logoutAdministrator = createAsyncThunk(
  'administrators/logoutAdministrator',
  async (param, { dispatch }) => {
    try {
      const res = await putApi(`${BASE_URL}/account/logout`, {});

      dispatch(
        enqueueSnackbar({
          message: 'ログアウトしました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * パスワードリセットメール送信
 */
export const sendMessageByPasswordResetAdministrator = createAsyncThunk(
  'administrators/sendMessageByPasswordResetAdministrator',
  async (param, { dispatch }) => {
    try {
      const params = {
        email: param.email,
      };

      const res = await axios.put(`${BASE_URL}/account/reset_password_email`, params);

      console.log('response', res);

      dispatch(
        enqueueSnackbar({
          message: 'パスワードリセット用のメールを送信しました。ご確認ください。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * パスワードリセット
 */
export const resetPasswordAdministrator = createAsyncThunk(
  'administrators/resetPasswordAdministrator',
  async (param, { dispatch }) => {
    try {
      const params = {
        new_password: param.password,
      };

      const res = await axios.put(
        `${BASE_URL}/account/reset_password/?reset_password_token=${param.resetPasswordToken}`,
        params,
      );

      console.log('response', res);

      dispatch(
        enqueueSnackbar({
          message: 'パスワードリセットが完了しました。ログインしてください。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * メール認証
 */
export const applyActivationAdministrator = createAsyncThunk(
  'administrators/applyActivationAdministrator',
  async (param, { dispatch }) => {
    try {
      const params = {
        apply_activation_token: param.token,
      };

      const res = await axios.put(
        `${BASE_URL}/account/apply_activation/?apply_activation_token=${param.token}`,
        params,
      );

      console.log('response', res);

      dispatch(
        enqueueSnackbar({
          message: '認証が完了しました。管理者から承認されるのをお待ちください。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return res;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * 管理者情報を更新する
 */
export const updateAdministrator = createAsyncThunk(
  'administrators/updateAdministrator',
  async (param) => {
    const body = {
      image: param.image,
      admin_name: param.admin_name,
      tel: param.tel,
      other: param.other,
      status_code: param.status_code,
    };

    const res = await putApi(`${BASE_URL}/admins/${param.id}`, body);

    return {
      id: param.id,
      admin: res.data.data,
    };
  },
);

/**
 * 管理者情報を更新する
 */
export const updateAdminAdministrator = createAsyncThunk(
  'administrators/updateAdminAdministrator',
  async (param) => {
    const body = {
      image: param.image,
      admin_name: param.admin_name,
      tel: param.tel,
      other: param.other,
      status_code: param.status_code,
    };

    const res = await putApi(`${BASE_URL}/admins/super_admin/${param.id}`, body);

    return {
      id: param.id,
      admin: res.data.data,
    };
  },
);

const administratorAdapter = createEntityAdapter({
  selectId: (e) => e.admin_id,
});

export const administratorSlice = createSlice({
  name: 'administrators',
  initialState: administratorAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    addTag: administratorAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllAdministrators.fulfilled, (state, action) => {
      administratorAdapter.setAll(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(fetchAllAdministrators.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdministrator.fulfilled, (state, action) => {
      administratorAdapter.upsertOne(state, { ...action.payload.admin });
      state.loading = false;
    });
    builder.addCase(fetchMyAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMyAccount.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchMyAccount.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(loginAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loginAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(logoutAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(logoutAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdministrator.fulfilled, (state, action) => {
      administratorAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.admin },
      });
      state.loading = false;
    });
    builder.addCase(updateAdminAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminAdministrator.fulfilled, (state, action) => {
      administratorAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.admin },
      });
      state.loading = false;
    });
    builder.addCase(sendMessageByPasswordResetAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendMessageByPasswordResetAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(sendMessageByPasswordResetAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetPasswordAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPasswordAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPasswordAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(applyActivationAdministrator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(applyActivationAdministrator.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(applyActivationAdministrator.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

export const administratorSelectors = administratorAdapter.getSelectors(
  (state) => state.administrators,
);

export default administratorSlice.reducer;
