import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

export function formatYYYYMMDD(date) {
  if (date) {
    return format(new Date(date), 'yyyy年MM月dd日', { locale: ja });
  }

  return '';
}

export function formatDateTime(date) {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd HH:mm', { locale: ja });
  }

  return '';
}
