import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { CheckboxField } from 'components/Form/CheckboxField';
import { Form } from 'components/Form/Form';
import { InputField } from 'components/Form/InputField';

import { useAuth } from 'lib/auth';

import { loginAdministrator } from 'stores/administratorSlice';
import { EMAIL_PATTERN, PASSWORD_LENGTH_PATTERN } from 'validation/validationPattern';

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required('メールアドレスを入力してください。')
      .matches(EMAIL_PATTERN.regex, EMAIL_PATTERN.message),
    password: yup
      .string()
      .required('パスワードを入力してください。')
      .matches(PASSWORD_LENGTH_PATTERN.regex, PASSWORD_LENGTH_PATTERN.message),
  })
  .required();

export const LoginForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { login } = useAuth();
  const loading = useSelector((state) => state.administrators.loading);

  const onSubmit = async (data) => {
    const params = {
      email: data.email,
      password: data.password,
    };

    const res = await dispatch(loginAdministrator(params));

    const result = unwrapResult(res);

    await login({
      email: data.email,
      password: data.password,
      remember: data.remember,
      accessToken: result.data.access_token,
    });

    onSuccess();
  };

  return (
    <Card
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        ログイン
      </Typography>
      <Box noValidate sx={{ mt: 1 }}>
        <Form
          schema={schema}
          options={{
            shouldUnregister: true,
            defaultValues: {
              remember: localStorage.getItem('remember')?.toLowerCase() === 'true' ?? false,
              email:
                localStorage.getItem('remember')?.toLowerCase() === 'true'
                  ? localStorage.getItem('email')
                  : '',
              password: localStorage.getItem('password') ?? '',
            },
          }}
          onSubmit={onSubmit}
        >
          {({ register, formState, control }) => (
            <>
              <InputField
                id="email"
                name="email"
                label="メールアドレス"
                error={formState.errors.email}
                registration={register('email')}
                helperText={formState.errors.email?.message}
                required
                autoComplete="email"
                autoFocus
              />
              <InputField
                id="password"
                name="password"
                type="password"
                label="パスワード"
                error={formState.errors.password}
                registration={register('password')}
                helperText={formState.errors.password?.message}
                required
                autoComplete="current-password"
                autoFocus
              />

              <CheckboxField
                control={control}
                label=""
                items={[{ name: 'remember', label: 'Remember me' }]}
                helperText={formState.errors.product?.message}
              />

              <LoadingButton
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                type="submit"
                loading={loading}
              >
                ログイン
              </LoadingButton>
            </>
          )}
        </Form>
      </Box>
      <Grid container spacing={8}>
        <Grid item xs>
          <Link href="../auth/reset" variant="body2">
            パスワードを忘れた方はこちら
          </Link>
        </Grid>
        <Grid item>
          <Link href="../auth/register" variant="body2">
            アカウントをお持ちでない方はこちら
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {};
