import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { APP_VIEW } from 'routes/urls';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <LoginForm onSuccess={() => navigate(APP_VIEW)} />
      </Box>
    </Layout>
  );
};
