/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi, postApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * 請求情報を全件取得する
 */
export const fetchAllComments = createAsyncThunk(
  'comments/fetchAllComments',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
      category_item: data.product ? 1 : 0,
      comment: data.comment,
      tab_status: data.tabStatus,
    };

    console.log('commentsAPI : param', param);

    try {
      const res = await getApi(
        `${BASE_URL}/comments`,
        // ここに「params」というキー名でセットし、クエリパラメータを指定する
        param,
      );

      console.log('commentsAPI response', res);

      return {
        res: res.data,
        total: res.meta.total,
      }; // action.payloadとしてReducerで読み取れる
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * コメント詳細を取得する
 */
export const fetchComment = createAsyncThunk('comments/fetchComment', async (id) => {
  const res = await getApi(`${BASE_URL}/comments/items/${id}`);

  console.log('commentAPI : 接続', res);

  return {
    data: res.data,
    status: res.meta.correspondence_status_list,
  };
});

/**
 * コメントを作成する
 */
export const createComment = createAsyncThunk(
  'comments/createComment',
  async ({ itemId, comment }, { dispatch }) => {
    try {
      const params = {
        item_id: itemId,
        comment,
      };

      const res = await postApi(`${BASE_URL}/comments/items/${itemId}`, params);

      dispatch(
        enqueueSnackbar({
          message: 'コメントを追加しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        data: res,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * コメントステータスを更新する
 */
export const updateComment = createAsyncThunk(
  'comments/updateComment',
  async ({ id, status }, { dispatch }) => {
    try {
      const params = {
        correspondence_status_code: status,
        comment_id: id,
      };

      const res = await putApi(`${BASE_URL}/comments/${id}/respond`, params);

      console.log('responseAPI', res);

      dispatch(
        enqueueSnackbar({
          message: 'ステータスを更新しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        comment_id: id,
        comment: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * コメントステータスを非表示にする
 */
export const hiddenComment = createAsyncThunk(
  'comments/hiddenComment',
  async (id, { dispatch }) => {
    console.log('commentAPI', id);

    try {
      const params = {
        comment_id: id,
      };

      const res = await putApi(`${BASE_URL}/comments/${id}/hidden`, params);

      console.log('responseAPI', res);

      dispatch(
        enqueueSnackbar({
          message: 'コメントを削除しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        id,
        comment: res.data,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const commentsAdapter = createEntityAdapter({
  selectId: (e) => e.comment_id,
});

export const commentSlice = createSlice({
  name: 'comments',
  initialState: commentsAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: commentsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllComments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllComments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAllComments.fulfilled, (state, action) => {
      commentsAdapter.setAll(state, action.payload.res);
      state.loading = false;
    });
    builder.addCase(fetchComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchComment.fulfilled, (state, action) => {
      commentsAdapter.upsertMany(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(createComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createComment.fulfilled, (state, action) => {
      // upsertOneは一つの配列の中にぶち込むよってこと。複数入れたいならupsertMany
      commentsAdapter.upsertOne(state, action.payload.data);
      state.loading = false;
    });
    builder.addCase(updateComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateComment.fulfilled, (state, action) => {
      commentsAdapter.updateOne(state, {
        id: action.payload.comment_id,
        changes: { ...action.payload.comment },
      });
      state.loading = false;
    });
    builder.addCase(hiddenComment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(hiddenComment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(hiddenComment.fulfilled, (state, action) => {
      commentsAdapter.removeOne(state, {
        id: action.payload.id,
      });
      state.loading = false;
    });
  },
});

export const commentSelectors = commentsAdapter.getSelectors((state) => state.comments);

export default commentSlice.reducer;
