import { Button } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const ButtonStyle = styled(Button)(({ color }) => ({
  backgroundColor: color === 'error' ? red[600] : green[600],
  color: 'white',
  '&:hover': {
    backgroundColor: color === 'error' ? red[400] : green[400],
  },
  fontWeight: 'bold',
}));

export const SubmitButton = (props) => {
  const { children, onClick, variant, type, size, sx, fullWidth, disabled, color } = props;

  return (
    <ButtonStyle
      variant={variant}
      onClick={onClick}
      type={type}
      size={size}
      sx={sx}
      fullWidth={fullWidth}
      disabled={disabled}
      color={color}
    >
      {children}
    </ButtonStyle>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.any,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

SubmitButton.defaultProps = {
  variant: 'contained',
  type: 'submit',
  size: 'large',
  sx: {},
  fullWidth: false,
  onClick: undefined,
  disabled: false,
  color: 'success',
};
