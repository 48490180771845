import { Alert, AlertTitle } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { SubmitButton } from 'components/Elements/Button/SubmitButton';
import { Spinner } from 'components/Elements/Spinner';
import { AuthProvider } from 'lib/auth';
import store from 'stores/index';

const ErrorFallback = () => (
  <div>
    <Alert severity="error">
      <AlertTitle>エラーが発生しました。</AlertTitle>
    </Alert>
    <SubmitButton onClick={() => window.location.assign(window.location.origin)}>
      Refresh
    </SubmitButton>
  </div>
);

export const AppProvider = (props) => {
  const { children } = props;

  const queryClient = new QueryClient();

  return (
    <React.Suspense fallback={<Spinner />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Provider store={store}>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  maxSnack={3}
                  autoHideDuration={1000}
                >
                  <Router>{children}</Router>
                </SnackbarProvider>
              </Provider>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};

AppProvider.propTypes = {
  children: PropTypes.element,
};

AppProvider.defaultProps = {
  children: null,
};
