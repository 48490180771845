/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

export const fetchUzoneNumber = createAsyncThunk(
  'info/fetchUzoneNumber',
  async (data, { dispatch }) => {
    console.log(data);
    try {
      const params = {
        maker_id: data?.makerId,
        genuine_part_no: data?.genuinePartNo,
      };
      const res = await getApi(`${BASE_URL}/items/uzone_number`, params);
      console.log(res);
      dispatch(
        enqueueSnackbar({
          message: 'UZone品番の取得に成功しました',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
          },
        }),
      );

      return {
        data: res,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'UZone品番の取得に失敗しました',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

const uzoneNumberAdapter = createEntityAdapter({
  selectId: (e) => e.parts_no,
});

const uzoneNumberSlice = createSlice({
  name: 'uzoneNumber',
  initialState: uzoneNumberAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: uzoneNumberAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUzoneNumber.fulfilled, (state, action) => {
      state.loading = false;
      uzoneNumberAdapter.setAll(state, action.payload.data);
    });
  },
});

export const uzoneNumberSelector = uzoneNumberAdapter.getSelectors((state) => state.uzoneNumber);

export default uzoneNumberSlice.reducer;
