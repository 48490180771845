/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export const Footer = () => (
  <Box
    component="footer"
    sx={{
      py: 3,
      px: 2,
      mt: 'auto',
      display: 'flex',
      backgroundColor: (_theme) =>
        _theme.palette.mode === 'light' ? _theme.palette.grey[200] : _theme.palette.grey[800],
    }}
  >
    <CssBaseline />

    <Box
      component="footer"
      sx={{
        px: 0,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg" sx={{ ml: '0' }}>
        <Grid container className="footer">
          <Grid item xs={2.5}>
            <Link href="#" underline="none" color="inherit">
              PARTS ONE inc.
            </Link>
          </Grid>
          {/* <Grid item xs={2}>
              <Link href="#" underline="none" color="inherit">
                コーポレートサイト
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Link href="#" underline="none" color="inherit">
                利用規約
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link href="#" underline="none" color="inherit">
                プライバシーポリシー
              </Link>
            </Grid>
            <Grid item xs={2.4}>
              <Link href="#" underline="none" color="inherit">
                特定商取引法に基づく表示
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Link href="#" underline="none" color="inherit">
                古物営業法に基づく表示.
              </Link>
            </Grid> */}
        </Grid>
      </Container>
    </Box>
  </Box>
);
