import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

import { override } from './css';

export const Spinner = (props) => {
  const { size, color } = props;

  return <ClipLoader size={size} color={color} loading css={override} speedMultiplier={1.5} />;
};

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

Spinner.defaultProps = {
  size: 150,
  color: '#123abc',
};
