import * as React from 'react';
import { useRoutes } from 'react-router-dom';

import { Login } from 'features/auth/routes/Login';
import { Dashboard } from 'features/misc';
import useNotifier from 'hooks/useNotifier';
import { useAuth } from 'lib/auth';

import protectedRoutes from './protected';
import { publicRoutes } from './public';

const AppRoutes = () => {
  const { user } = useAuth();
  useNotifier();

  const commonRoutes = [
    {
      path: '/',
      element: user ? <Dashboard /> : <Login />,
    },
  ];

  const routes = user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <div>{element}</div>;
};

export default AppRoutes;
