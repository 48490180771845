import { initReactQueryAuth } from 'react-query-auth';

import { setApiToken } from './axios';

async function loadUser() {
  if (localStorage.getItem('accessToken')) {
    const data = {
      email: localStorage.getItem('email'),
      password: localStorage.getItem('password'),
      remember: localStorage.getItem('remember'),
      accessToken: localStorage.getItem('accessToken'),
    };

    setApiToken(localStorage.getItem('accessToken'));

    return data;
  }

  return null;
}

async function loginFn(data) {
  const { email, password, remember, accessToken } = data;

  localStorage.setItem('remember', remember);
  localStorage.setItem('accessToken', accessToken);

  localStorage.setItem('password', remember ? password : '');
  localStorage.setItem('email', email);

  setApiToken(accessToken);

  return data;
}

async function logoutFn() {
  localStorage.setItem('accessToken', '');
  window.location.assign(window.location.origin);
}

const authConfig = {
  loadUser,
  loginFn,
  logoutFn,
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
