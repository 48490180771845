/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

import { enqueueSnackbar } from './notificationSlice';

/**
 * ユーザーを全件取得する
 */
export const fetchAllWithdrawals = createAsyncThunk(
  'users/fetchAllWithdrawals',
  async (data, { dispatch }) => {
    const param = {
      per_page: data.rowsPerPage,
      page: data.page,
    };

    try {
      const res = await getApi(`${BASE_URL}/withdrawal_request`, param);

      return {
        res,
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'エラーが発生しました。',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }),
      );

      throw error;
    }
  },
);

/**
 * ユーザーの退会申請を承認する
 */
export const approveWithdrawal = createAsyncThunk(
  'withdrawal_request/withdrawal_request',
  async ({ withdrawalId, internalMemo }) => {
    const res = await putApi(`${BASE_URL}/withdrawal_request/${withdrawalId}`, {
      internal_memo: internalMemo,
    })
      .then((suc) => suc.resppnse)
      .catch((err) => err.response);

    return {
      data: res?.data,
      error: res?.data?.errors,
    };
  },
);

/**
 * ユーザーの退会内容を取得する
 */
export const fetchWithdrawalDetails = createAsyncThunk(
  'withdrawal/fetchWithdrawalDetails',
  async (id) => {
    const res = await getApi(`${BASE_URL}/withdrawal_request/${id}`);

    return {
      data: res.data,
    };
  },
);

const withdrawalAdapter = createEntityAdapter({
  selectId: (e) => e.user_id,
});

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: withdrawalAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    addTag: withdrawalAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllWithdrawals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllWithdrawals.fulfilled, (state, action) => {
      withdrawalAdapter.setAll(state, action.payload.res.data);
      state.loading = false;
    });
    builder.addCase(fetchWithdrawalDetails.fulfilled, (state, action) => {
      withdrawalAdapter.updateOne(state, {
        withdrawal_request_id: action.payload.data,
      });
    });
    builder.addCase(approveWithdrawal.fulfilled, (state, action) => {
      withdrawalAdapter.updateOne(state, {
        error: action?.payload?.error,
      });
      state.loading = false;
    });
  },
});

export const withdrawalSelectors = withdrawalAdapter.getSelectors((state) => state.withdrawal);

export default withdrawalSlice.reducer;
