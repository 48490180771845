export const APP_VIEW = '/app';

export const AUTH_LOGIN_VIEW = '/auth/login';

export const AUTH_REGISTER_VIEW = '/auth/register';

export const AUTH_RESET_VIEW = '/auth/reset';

export const DASHBOARD_VIEW = `${APP_VIEW}/dashboard`;

export const USER_LIST_VIEW = `${APP_VIEW}/users`;

export const USER_CREATE_VIEW = `${APP_VIEW}/createUsers`;

export const PRODUCT_LIST_VIEW = `${APP_VIEW}/products`;

export const PRODUCT_CREATE_VIEW = `${PRODUCT_LIST_VIEW}/create`;

export const PRODUCT_REBUILDCREATE_VIEW = `${PRODUCT_LIST_VIEW}/rebuildCreate`;

export const EXHIBIT_LIST_VIEW = `${APP_VIEW}/exhibits`;

export const EXHIBIT_CREATE_VIEW = `${EXHIBIT_LIST_VIEW}/create`;

export const REQUEST_LIST_VIEW = `${APP_VIEW}/requests`;

export const INQUIRIES_LIST_VIEW = `${APP_VIEW}/inquiries`;

export const ORDER_LIST_VIEW = `${APP_VIEW}/orders`;

export const PAYMENTS_LIST_VIEW = `${APP_VIEW}/payments`;

export const ADMINISTRATOR_LIST_VIEW = `${APP_VIEW}/administrators`;

export const COMMENT_LIST_VIEW = `${APP_VIEW}/comments`;

export const ATRS_IMPORT_ERROR_LIST_VIEW = `${APP_VIEW}/atrsImportErrors`;

export const PRODUCT_MASTER_VIEW = `${APP_VIEW}/productsMaster`;

export const IMPORT_VEHICLE_MASTER = `${APP_VIEW}/importVehicleMaster`;

export const WITHDRAWAL_LIST_VIEW = `${APP_VIEW}/withdrawals`;
