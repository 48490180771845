/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { getApi, putApi } from 'lib/axios';
import { BASE_URL } from 'utils/const';

/**
 * 部品リクエスト詳細を取得する
 */
export const fetchRequest = createAsyncThunk('requests/fetchRequest', async (id) => {
  const res = await getApi(`${BASE_URL}/request/${id}`, {});

  return {
    id,
    request: res.data,
  };
});

/**
 * 部品リクエストを更新する
 */
export const updateRequest = createAsyncThunk('requests/updateRequest', async (param) => {
  const body = {
    status_code: param.status_code,
    request_memo: param.request_memo,
  };

  await putApi(`${BASE_URL}/request/${param.requestId}`, body);

  return {
    id: param.requestId,
    request: param,
  };
});

const requestDetailAdapter = createEntityAdapter({
  selectId: (e) => e.id,
});

export const requestDetailSlice = createSlice({
  name: 'requestsDetail',
  initialState: requestDetailAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: requestDetailAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRequest.fulfilled, (state, action) => {
      requestDetailAdapter.upsertOne(state, action.payload.request);
      state.loading = false;
    });
    builder.addCase(updateRequest.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRequest.fulfilled, (state, action) => {
      requestDetailAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.request },
      });
      state.loading = false;
    });
  },
});

export const requestDetailSelectors = requestDetailAdapter.getSelectors(
  (state) => state.requestDetail,
);

export default requestDetailSlice.reducer;
