/* eslint-disable no-param-reassign */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { getApi } from 'lib/axios';
import { USER_BASE_URL } from 'utils/const';

// 車種の取得（makersの後のやつ）
export const fetchCarsInfo = createAsyncThunk('carsInfo/fetchCarsInfo', async (id) => {
  const res = await getApi(`${USER_BASE_URL}/master/cars/info?maker_id=${id}`);

  return {
    data: res.data,
  };
});

const carsInfoAdapter = createEntityAdapter();

const carsInfoSlice = createSlice({
  name: 'carsInfo',
  initialState: carsInfoAdapter.getInitialState({
    loading: true,
  }),
  reducers: {
    addTag: carsInfoAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarsInfo.fulfilled, (state, action) => {
      state.loading = false;
      carsInfoAdapter.setAll(state, action.payload.data);
    });
  },
});

export const carsInfoSelector = carsInfoAdapter.getSelectors((state) => state.carsInfo);

export default carsInfoSlice.reducer;
