import { Checkbox, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

export const CheckboxField = (props) => {
  const { control, label, items, disabled, helperText } = props;

  return (
    <>
      <Typography variant="subtitle2" align="left">
        {label}
      </Typography>
      {items.map((item) => (
        <Stack key={item.name}>
          <FormControlLabel
            control={
              <Controller
                name={item.name}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    disabled={disabled}
                  />
                )}
              />
            }
            label={item.label}
          />
        </Stack>
      ))}
      <Stack>{helperText ? <FormHelperText error>{helperText}</FormHelperText> : null}</Stack>
    </>
  );
};

CheckboxField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  helperText: PropTypes.string,
};

CheckboxField.defaultProps = {
  disabled: false,
  helperText: '',
};
